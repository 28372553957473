import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Stack from '../components/systems/stack';
import SEO from '../components/layout/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Datenschutz" shouldIndex={false} mdxType="SEO" />
    <Stack gap="20px" centered={false} mdxType="Stack">
      <h1>{`DATENSCHUTZERKLÄRUNG`}</h1>
      <h2>{`1) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen`}</h2>
      <p><strong parentName="p">{`1.1`}</strong>{`  Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse. Im Folgenden informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können.`}</p>
      <p><strong parentName="p">{`1.2`}</strong>{`  Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Beate Landgraf (Psychologie-für-Sie), Lindenweg 36, 91091 Großenseebach, Deutschland, Tel.: +49 175 474 45 61‬, E-Mail: `}<a parentName="p" {...{
          "href": "mailto:mail@praxis-landgraf.de."
        }}>{`mail@praxis-landgraf.de.`}</a>{` Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.‬`}</p>
      <p><strong parentName="p">{`1.3 `}</strong>{` Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile erkennen.`}</p>
      <h2>{`2) Datenerfassung beim Besuch unserer Website`}</h2>
      <p>{`Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:`}</p>
      <ul>
        <li parentName="ul">{`Unsere besuchte Website`}</li>
        <li parentName="ul">{`Datum und Uhrzeit zum Zeitpunkt des Zugriffes`}</li>
        <li parentName="ul">{`Menge der gesendeten Daten in Byte`}</li>
        <li parentName="ul">{`Quelle/Verweis, von welchem Sie auf die Seite gelangten`}</li>
        <li parentName="ul">{`Verwendeter Browser`}</li>
        <li parentName="ul">{`Verwendetes Betriebssystem`}</li>
        <li parentName="ul">{`Verwendete IP-Adresse (ggf.: in anonymisierter Form)`}</li>
      </ul>
      <p>{`Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.`}</p>
      <h2>{`3) Cookies`}</h2>
      <p>{`Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen (sog. persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Die Dauer der jeweiligen Cookie-Speicherung können Sie der Übersicht zu den Cookie-Einstellungen Ihres Webbrowsers entnehmen.
Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen den Bestellprozess zu vereinfachen (z.B. Merken des Inhalts eines virtuellen Warenkorbs für einen späteren Besuch auf der Website). Sofern durch einzelne von uns eingesetzte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchführung des Vertrages, gemäß Art. 6 Abs. 1 lit. a DSGVO im Falle einer erteilten Einwilligung oder gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestmöglichen Funktionalität der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
Bitte beachten Sie, dass Sie Ihren Browser so einstellen können, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen können. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden Links:
Internet Explorer: `}<a parentName="p" {...{
          "href": "https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
        }}>{`https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies`}</a>{`
Firefox: `}<a parentName="p" {...{
          "href": "https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
        }}>{`https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen`}</a>{`
Chrome: `}<a parentName="p" {...{
          "href": "https://support.google.com/chrome/answer/95647?hl=de&hlrm=en"
        }}>{`https://support.google.com/chrome/answer/95647?hl=de&hlrm=en`}</a>{`
Safari: `}<a parentName="p" {...{
          "href": "https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14"
        }}>{`https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14`}</a>{`
Opera: `}<a parentName="p" {...{
          "href": "https://help.opera.com/de/latest/web-preferences/#cookies"
        }}>{`https://help.opera.com/de/latest/web-preferences/#cookies`}</a>{`
Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalität unserer Website eingeschränkt sein kann.`}</p>
      <h2>{`4) Kontaktaufnahme`}</h2>
      <p>{`Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.`}</p>
      <h2>{`5) Datenverarbeitung bei Eröffnung eines Kundenkontos und zur Vertragsabwicklung`}</h2>
      <p>{`Gemäß Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten weiterhin erhoben und verarbeitet, wenn Sie uns diese zur Durchführung eines Vertrages oder bei der Eröffnung eines Kundenkontos mitteilen. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Eine Löschung Ihres Kundenkontos ist jederzeit möglich und kann durch eine Nachricht an die o.g. Adresse des Verantwortlichen erfolgen. Wir speichern und verwenden die von Ihnen mitgeteilten Daten zur Vertragsabwicklung. Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten mit Rücksicht auf steuer- und handelsrechtliche Aufbewahrungsfristen gesperrt und nach Ablauf dieser Fristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich erlaubte weitere Datenverwendung von unserer Seite vorbehalten wurde.`}</p>
      <h2>{`6) Nutzung Ihrer Daten zur Direktwerbung`}</h2>
      <p>{`Anmeldung zu unserem E-Mail-Newsletter
Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden wir Ihnen regelmäßig Informationen zu unseren Angeboten. Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mailadresse. Die Angabe weiterer Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen zu können. Für den Versand des Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst dann einen E-Mail Newsletter übermitteln werden, wenn Sie uns ausdrücklich bestätigt haben, dass Sie in den Empfang von Newsletter einwilligen. Wir schicken Ihnen dann eine Bestätigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines entsprechenden Links zu bestätigen, dass Sie künftig den Newsletter erhalten wollen.
Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter speichern wir Ihre vom Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen zu können. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten werden ausschließlich für Zwecke der werblichen Ansprache im Wege des Newsletters benutzt. Sie können den Newsletter jederzeit über den dafür vorgesehenen Link im Newsletter oder durch entsprechende Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.`}</p>
      <h2>{`7) Datenverarbeitung zur Bestellabwicklung`}</h2>
      <p><strong parentName="p">{`7.1`}</strong>{`  Zur Abwicklung Ihrer Bestellung arbeiten wir mit dem / den nachstehenden Dienstleistern zusammen, die uns ganz oder teilweise bei der Durchführung geschlossener Verträge unterstützen. An diese Dienstleister werden nach Maßgabe der folgenden Informationen gewisse personenbezogene Daten übermittelt.
Die von uns erhobenen personenbezogenen Daten werden im Rahmen der Vertragsabwicklung an das mit der Lieferung beauftragte Transportunternehmen weitergegeben, soweit dies zur Lieferung der Ware erforderlich ist. Ihre Zahlungsdaten geben wir im Rahmen der Zahlungsabwicklung an das beauftragte Kreditinstitut weiter, sofern dies für die Zahlungsabwicklung erforderlich ist. Sofern Zahlungsdienstleister eingesetzt werden, informieren wir Sie hierüber nachstehend explizit. Rechtsgrundlage für die Weitergabe der Daten ist Art. 6 Abs. 1 lit. b DSGVO.
`}<strong parentName="p">{`7.2 `}</strong>{`Verwendung von Paymentdienstleistern (Zahlungsdiensten)`}</p>
      <ul>
        <li parentName="ul">{`Stripe
Wenn Sie sich für eine Zahlungsart des Paymentdienstleisters Stripe entscheiden, erfolgt die Zahlungsabwicklung über den Paymentdienstleister Stripe Payments Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland, an den wir Ihre im Rahmen des Bestellvorgangs mitgeteilten Informationen nebst den Informationen über Ihre Bestellung (Name, Anschrift, Kontonummer, Bankleitzahl, evtl. Kreditkartennummer, Rechnungsbetrag, Währung und Transaktionsnummer) gemäß Art. 6 Abs. 1 lit. b DSGVO weitergeben. Die Weitergabe Ihrer Daten erfolgt ausschließlich zum Zwecke der Zahlungsabwicklung mit dem Payment-Dienstleister Stripe Payments Europe Ltd. und nur insoweit, als sie hierfür erforderlich ist. Nähere Informationen zum Datenschutz von Stripe finden Sie unter der URL `}<a parentName="li" {...{
            "href": "https://stripe.com/de/privacy#translation"
          }}>{`https://stripe.com/de/privacy#translation`}</a>{`.`}</li>
      </ul>
      <h2>{`8) Verwendung von Sozialen Medien: Videos`}</h2>
      <p>{`Verwendung von Vimeo-Videos`}</p>
      <p>{`Auf unserer Website sind Plugins des Videoportals Vimeo der Vimeo, LLC, 555 West 18th Street, New York, New York 10011, USA eingebunden. Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Vimeo her. Der Inhalt des Plugins wird von Vimeo direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch diese Einbindung erhält Vimeo die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie keinen Vimeo-Account besitzen oder gerade nicht bei Vimeo eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Vimeo in die USA übermittelt und dort gespeichert.
Sind Sie bei Vimeo eingeloggt, kann Vimeo den Besuch unserer Website Ihrem Vimeo-Account unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren (wie z.B. Betätigung des Start-Buttons eines Videos), wird diese Information ebenfalls direkt an einen Server von Vimeo übermittelt und dort gespeichert.
Die beschriebenen Datenverarbeitungsvorgänge erfolgen gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis des berechtigten Interesses von Vimeo an Marktforschung und der bedarfsgerechten Gestaltung des Vimeo-Dienstes.
Wenn Sie nicht möchten, dass Vimeo die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Vimeo-Account zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Vimeo ausloggen.
Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Vimeo sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Vimeo: `}<a parentName="p" {...{
          "href": "https://vimeo.com/privacy"
        }}>{`https://vimeo.com/privacy`}</a>{`
Vimeo, Inc. mit Sitz in den USA ist für das US-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: `}<a parentName="p" {...{
          "href": "https://www.privacyshield.gov/list"
        }}>{`https://www.privacyshield.gov/list`}</a>{`
Bei Videos von Vimeo, die auf unserer Seite eingebunden sind, ist das Trackingtool Google Analytics der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland, automatisch integriert. Dabei handelt es sich um ein eigenes Tracking von Vimeo, auf das wir keinen Zugriff haben und welches von unserer Seite nicht beeinflusst werden kann. Google Analytics verwendet für das Tracking sog. „Cookies“, dies sind Textdateien, die auf Ihrem Computer gespeichert werden und eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google übertragen und dort gespeichert, hierbei kann es auch zu einer Übermittlung an die Server der Google LLC. in den USA kommen.
Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das US-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: `}<a parentName="p" {...{
          "href": "https://www.privacyshield.gov/list"
        }}>{`https://www.privacyshield.gov/list`}</a>{`
Diese Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Grundlage des berechtigten Interesses von Vimeo an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken.
Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur Vornahme eines Widerspruchs.`}</p>
      <h2>{`9) Webanalysedienste`}</h2>
      <p>{`Netlify Analytics (Netlify Inc.)`}</p>
      <p>{`Dieses Angebot nutzt den Webanalysedienst der Netlify, Inc, 2325 3rd Street, Suite 215, San Francisco, CA 94107, USA („Netlify“). Mithilfe von Netlify werden auf Basis unseres berechtigten Interesses an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken gemäß Art. 6 Abs. 1 lit. f DSGVO pseudonymisierte Besucherdaten gesammelt, ausgewertet und gespeichert. Aus diesen Daten können zum selben Zweck pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden. Netlify verwendet sog. Cookies, das sind kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden. Diese Cookies dienen unter anderem dazu, den Browser wiederzuerkennen, und ermöglichen so eine genauere Ermittlung der Statistikdaten. Die Daten der IP-Adresse des Nutzers werden ebenfalls erhoben, sie werden aber sofort nach der Erhebung und vor deren Speicherung pseudonymisiert, um eine Personenbeziehbarkeit auszuschließen.
Netlify mit Sitz in den USA ist für das US-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
Netlify wird in keinem Fall Ihre personenbezogenen Daten mit anderen Daten von Netlify zusammenführen.
Sie können die Verwendung von Cookies auf Ihrem Computer generell deaktivieren, indem Sie Ihren Internetbrowser so einstellen, dass zukünftig keine Cookies mehr auf Ihrem Computer abgelegt werden können bzw. bereits abgelegte Cookies gelöscht werden. Das Abschalten sämtlicher Cookies kann jedoch dazu führen, dass einige Funktionen auf unseren Internetseiten nicht mehr ausgeführt werden können.
Die Datenschutzrichtlinien von Netlify können Sie unter nachstehender Internetadresse aufrufen: `}<a parentName="p" {...{
          "href": "https://www.netlify.com/privacy/"
        }}>{`https://www.netlify.com/privacy/`}</a>{`
Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur Vornahme eines Widerspruchs.`}</p>
      <h2>{`10) Tools und Sonstiges`}</h2>
      <p><strong parentName="p">{`10.1`}</strong>{` Google Kundenrezensionen (ehemals Google Zertifizierter-Händler-Programm)`}</p>
      <p>{`Wir arbeiten mit Google im Rahmen des Programms „Google Kundenrezensionen“ zusammen. Der Anbieter ist Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (“Google”). Dieses Programm gibt uns die Möglichkeit, Kundenrezensionen von Nutzern unserer Website einzuholen. Hierbei werden Sie nach einem Einkauf auf unserer Website gefragt, ob Sie an einer E-Mail-Umfrage von Google teilnehmen möchten. Wenn Sie Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO erteilen, übermitteln wir Ihre E-Mail-Adresse an Google. Sie erhalten eine E-Mail von Google Kundenrezensionen, in der Sie gebeten werden, die Kauferfahrung auf unserer Website zu bewerten. Die von Ihnen abgegebene Bewertung wird anschließend mit unseren anderen Bewertungen zusammengefasst und in unserem Logo Google Kundenrezensionen sowie in unserem Merchant Center-Dashboard angezeigt. Außerdem wird Ihre Bewertung für Google Verkäuferbewertungen genutzt. Im Rahmen der Nutzung von Google Kundenrezensionen kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.
Sie können Ihre Einwilligung jederzeit durch eine Nachricht an den für die Datenverarbeitung Verantwortlichen oder gegenüber Google widerrufen.
Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: `}<a parentName="p" {...{
          "href": "https://www.privacyshield.gov/list"
        }}>{`https://www.privacyshield.gov/list`}</a>{`
Weitere Informationen zum Datenschutz von Google im Zusammenhang mit dem Programm Google Kundenrezensionen können Sie unter nachstehendem Link abrufen: `}<a parentName="p" {...{
          "href": "https://support.google.com/merchants/answer/7188525?hl=de"
        }}>{`https://support.google.com/merchants/answer/7188525?hl=de`}</a>{`
Weitere Informationen zum Datenschutz von Google Verkäuferbewertungen können Sie unter diesem Link nachlesen: `}<a parentName="p" {...{
          "href": "https://support.google.com/google-ads/answer/2375474"
        }}>{`https://support.google.com/google-ads/answer/2375474`}</a></p>
      <p><strong parentName="p">{`10.2`}</strong>{` - LogRocket`}</p>
      <p>{`Auf unserer Website haben wir zur automatischen Übermittlung von Fehlerberichten den Dienst der LogRocket Inc. One Kendall Square, B6201, Cambridge, MA 02139, USA („LogRocket“) implementiert.
Bei technischen Komplikationen oder Funktionalitätsbeeinträchtigungen im Zusammenhang mit dem Betrieb der Website sendet LogRocket automatische Fehlerberichte, die Informationen zu der jeweiligen Fehlerquelle und deren Ursprung enthalten. Übermittelt werden hierbei sowohl Server-Informationen als auch Nutzungsparameter wie die IP-Adresse, der eingesetzte Browser, Zeitstempel und die angesteuerte URL.
Fehlerberichte können unter Umständen je nach Ursprung des Fehlers auch weitergehende personenbezogene Kundendaten enthalten, die wir im Zuge von Vertragsschlüssen erhoben und gespeichert haben (insbesondere Vor- und Zuname, Anschrift, Mailadresse). Dies ist immer dann denkbar, wenn der Fehler im Zusammenhang mit einer softwarebasierten Verarbeitung von Kundendaten auftritt.
Sofern an den so übermittelten Informationen auch personenbezogene Daten teilhaben, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO aufgrund unseres berechtigten Interesses an einer effizienten Fehlerursachenanalyse zur Verbesserung der Zuverlässigkeit und Funktionalität unseres Internetauftritts.
LogRocket mit Sitz in den USA ist für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
Weitere Informationen zu den Datenschutzbestimmungen von LogRocket finden Sie hier: `}<a parentName="p" {...{
          "href": "https://logrocket.com/privacy/"
        }}>{`https://logrocket.com/privacy/`}</a></p>
      <h2>{`11) Rechte des Betroffenen`}</h2>
      <p><strong parentName="p">{`11.1`}</strong>{`  Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), über die wir Sie nachstehend informieren:`}</p>
      <ul>
        <li parentName="ul">{`Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;`}</li>
        <li parentName="ul">{`Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;`}</li>
        <li parentName="ul">{`Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;`}</li>
        <li parentName="ul">{`Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen;`}</li>
        <li parentName="ul">{`Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.`}</li>
        <li parentName="ul">{`Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;`}</li>
        <li parentName="ul">{`Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt;`}</li>
        <li parentName="ul">{`Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.`}</li>
      </ul>
      <h2>{`11.2 WIDERSPRUCHSRECHT`}</h2>
      <p>{`WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.
WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN.
MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.`}</p>
      <h2>{`12) Dauer der Speicherung personenbezogener Daten`}</h2>
      <p>{`Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).
Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.
Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt.
Sofern sich aus den sonstigen Informationen dieser Erklärung über spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.`}</p>
    </Stack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      